<template lang="pug">
div(class="login__card__inner")
    z-input(type="text" label="Адрес почты" autofocus class="login-field" v-model="email")
    z-input(type="text" label="Имя" class="login-field" v-model="name")
    z-input(type="password" label="Пароль" class="login-field" v-model="password" @keydown.enter="passwordEnter")
    div(class="buttons")
        z-btn(@click="passwordEnter" :loading="authLoading") РЕГИСТРАЦИЯ
</template>

<script setup>
import { ref } from 'vue'

import {useAuth} from '../../store/auth'
const { authLoading, authStatus, tryLogin } = useAuth()

const email = ref('')
const name = ref('')
const password = ref('')

function passwordEnter () {
    if (email.value && password.value && name.value) {
        signup()
    }
}

async function signup () {
    await tryLogin({
        name: name.value,
        email: email.value,
        password: password.value,
        resource: '/signup/'
    })

    if (authStatus.value) {
        location = location.protocol + '//' + location.host
    }
}
</script>

<style scoped>

</style>
