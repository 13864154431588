<template lang="pug">
div(class="login__card__inner")
  z-input(type="text" label="Логин или адрес почты" class="login-field" v-model="email" autofocus)
  div(class="login-field__error")
  div(class="buttons")
    z-btn(:loading="authLoading" @click="restorePassword") СБРОСИТЬ ПАРОЛЬ
</template>

<script setup>
import { ref } from 'vue'
import { useAuth } from '../../store/auth'
const { authLoading, tryRestore } = useAuth()

const email = ref('')

function restorePassword () {
    if (email.value) {
        tryRestore({ email: email.value })
    }
}
</script>

<style scoped>

</style>
