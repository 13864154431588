<template lang="pug">
div(class="login__main")
    div(class="login__header")
        div(class="login__main__links")
            router-link( to="/login/signin" ) Вход
            router-link( to="/login/signup" ) Регистрация
            router-link( to="/login/restore") Забыли пароль?
    div(class="login__main__body")
        div(class="login__main__card z-card")
            router-view
</template>

<script>
export default {
    name: 'Login',
}
</script>

<style scoped>
.login__main {
    --login-header-height: var(--block-height-big);
    background: var(--panel_background);
    width: 100%;
    height: 100vh;
    /*var(--vh100);*/
    --card-width: 25rem;
}

/*@media (max-height: 33rem) {*/
/*    .login__main {*/
/*        --login-header-height: var(--block-height-medium);*/
/*    }*/
/*}*/

.login__header {
    height: var(--login-header-height);
    background: var(--login__header_background);
    color: var(--panel__header_color);
    display: flex;
    justify-content: center;
    width: 100%;
}

.login__main__links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--card-width);
}

.login__main__links .router-link-active {
    border-bottom: var(--active-tab-border-color) var(--tab-border-height) solid;
}

.login__main__links a {
    height: var(--login-header-height);
    line-height: var(--login-header-height);
    border-bottom: var(--inactive-tab-border-color) var(--tab-border-height) solid;
    padding: 0 1rem;
    color: var(--panel__header_color);
    text-decoration: none;
}

.login__main__links a:hover {
    /*background: var(--panel__header__menu-button_background-hover);*/
    backdrop-filter: brightness(1.1);
}

.login__main__body {
    height: calc(100% - var(--login-header-height));
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--regular-text-color);
}

.login__main__card {
    height: fit-content;
    width: var(--card-width);
    padding: 3rem;
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
}

.login__main__card ::v-deep(.buttons) {
    margin-top: 1rem;
}

.login__card__inner {
    /*background: beige;*/
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-field {
    /*color: white;*/
    margin-bottom: 1rem;
}


@media (max-height: 33rem) {
    .login__main__card {
        margin-top: 1rem;
        padding-top: 1rem;
        --login-header-height: var(--block-height-medium);
    }

    .login__main {
        --login-header-height: var(--block-height-medium);
    }
}

@media (max-width: 30rem) {
    .login__main {
        justify-content: stretch;
    }

    .login__main__card {
        margin: 0;
        width: 100%;
        height: 100%;
        align-items: baseline;
        /*padding: 1rem;*/
    }

    .login__main__links a {
        padding: 0 0.7rem;
    }
}

@media (max-width: 25rem) {
    .login__main__links .router-link-active {
        display: none;
    }

    .login__main__card {
        padding-right: 2rem;
        padding-left: 2rem;
    }
}
</style>
