<template lang="pug">
div(class="login__card__inner")
    z-input(type="text" class="login-field" v-model="email" autofocus autocomplete="username" label="Логин или адрес почты" @keydown.enter="emailEnter" )
    z-input(type="password" autocomplete="current-password" class="login-field" label="Пароль" v-model="password" @keydown.enter="passwordEnter" ref="refPassword")
    div(class="buttons")
        z-btn(@click="signin" :loading="authLoading") ВХОД
</template>

<script setup>
import { ref } from 'vue'

import {useAuth} from '../../store/auth'
const { authLoading, authStatus, tryLogin } = useAuth()

const email = ref('')
const password = ref('')
const refPassword = ref(null)
const errors = []

function emailEnter () {
    refPassword.value.focus()
}

function passwordEnter () {
    if (email.value && password.value) {
        signin()
    }
}

async function signin () {
    await tryLogin( {
        email: email.value,
        password: password.value,
        resource: '/signin/'
    })
    if (authStatus.value) {
        location = location.protocol + '//' + location.host
    }
}
</script>

<style scoped>
</style>
